import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Seo from '../components/seo';
import ReactMarkdown from 'react-markdown';
import { Container, Row, Col } from 'react-grid-system';

import Hero from '../components/rows/hero/index';
import Banner from '../images/hero-images/research-hero.jpg';
import TwoCol from '../components/rows/twocol/index';
import OneCol from '../components/rows/onecol/index';
import Follow from '../components/rows/cta/follow';
import Accordion from '../components/elements/accordion';

export default function Research({ data }) {
  return (
    <Layout>
      <Seo title="Research" />
      <Hero title="Research" banner={Banner} />
      {data.allStrapiResearching.edges.map(({ node }) => (
        <React.Fragment key={node}>
          <TwoCol
            title={node.res1.title}
            description={node.res1.description}
            link={node.res1.button_title}
            href={node.res1.button_link}
            image={node.res1.image.localFile.publicURL}
          />
          <div className="medical-bg bg-gray-100 py-52 flex flex-wrap my-56">
            <Container>
              <Row>
                <Col
                  md={7}
                  sm={12}
                  className="mx-auto text-center research-intro"
                >
                  <h2>
                    <strong>Advancing</strong> cardiac care
                  </h2>
                  <ReactMarkdown
                    children={node.res2_intro}
                    linkTarget="_blank"
                  />
                </Col>
              </Row>
              <Accordion data={node.res_acc} />
            </Container>
          </div>
        </React.Fragment>
      ))}
      <OneCol
        blueBG
        pdf
        title="Interested in participating in our research studies? Please call Debbie Nemtean at 905-895-4521 ext. 2068 for more information."
        link="Get In Touch Today"
        href="tel:905-895-4521,2068"
      />
      <Follow />
    </Layout>
  );
}

export const query = graphql`
  query Research {
    allStrapiResearching {
      edges {
        node {
          id # id of the node
          res1 {
            id
            title
            description
            image {
              localFile {
                publicURL
              }
            }
          }
          res2_intro
          res_acc {
            id
            title
            description
          }
        }
      }
    }
  }
`;
